import type * as React from 'react';
import { useCycle } from '@dx-ui/osc-cycle';

const ImageHeadlinerContent = ({ children }: React.PropsWithChildren) => {
  const { cycleId } = useCycle();
  return (
    <div id={cycleId} aria-live="polite" className="w-full">
      {children}
    </div>
  );
};

export { ImageHeadlinerContent };
export default ImageHeadlinerContent;
