import { defaultPublicVars, env } from '@dx-ui/framework-env';

export const publicEnvVars = [
  ...defaultPublicVars,
  'BROWSER_DX_GQL_URI',
  'CMS_BASE_URL',
  'CONDUCTRICS_URI',
  'DX_AUTH_APP_CUSTOMER_ID',
  'DX_AUTOCOMPLETE_URI',
  'ENABLE_BRANDS_SLUG',
  'GLOBAL_PRIVACY_URL',
  'HILTON_ASSETS_URI',
  'IS_LIVE_MODE',
  'LOCATION_PROVIDER_API',
  'PARTNER_FORGET_INFO_LINK',
] as const;

export const isProdApp = () => env('APP_ENV') === 'prd';

// cookie name for the real Adobe Audience Manager cookie
export const TMS_LIVE_COOKIE_NAME = 'TMS';
// in lower environments a dev cookie can be used since Adobe launch script overwrites any changes on page load, which makes testing challenging
export const TMS_DEV_COOKIE_NAME = 'TMS_DEV';

export const namespaces = [
  'common',
  'cpm-mapping-shared',
  'osc-accordion',
  'osc-alert',
  'osc-back-to-top',
  'osc-brands-header',
  'osc-brands-search',
  'osc-calendar',
  'osc-card-carousel',
  'osc-carousel',
  'osc-chip',
  'osc-date-picker',
  'osc-dialog',
  'osc-dynamic-grids',
  'osc-footer',
  'osc-form',
  'osc-header',
  'osc-hero-image',
  'osc-hero-text-overlay',
  'osc-info-popup',
  'osc-language-selector',
  'osc-link',
  'osc-location',
  'osc-marketing',
  'osc-marketing-brand-showcase',
  'osc-marketing-dx-dialog-with-content',
  'osc-marketing-dx-image-carousel',
  'osc-marketing-dynamic-grid',
  'osc-marketing-error',
  'osc-marketing-half-and-half',
  'osc-marketing-layout',
  'osc-pagination',
  'osc-rooms',
  'osc-scroll-animation',
  'osc-scrollable-tabs',
  'osc-shop-form',
  'osc-smb-profile-switcher',
  'osc-special-rates',
  'osc-speedbump',
  'osc-stepper',
  'osc-tooltip',
  'osc-video-player',
  'special-rates',
  'osc-join-form-join',
  'osc-join-form-form',
  'osc-join-modal-button',
  'osc-filmstrip',
];
