import { Filmstrip } from '@dx-ui/osc-filmstrip';
import type { FilmstripProps } from '@dx-ui/osc-filmstrip';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import type { BrandLinkProps } from '@dx-ui/osc-brand-buttons';
import { HeadingLevel } from '@dx-ui/osc-heading-level';
import cx from 'classnames';

export interface BrandFilmstripProps extends FilmstripProps {
  headline?: string;
  description?: string;
  link?: BrandLinkProps;
  brandComponentTheme?: 'light' | 'dark';
  textAlign: CmsAlignContent | undefined;
}

export const BrandFilmstrip = ({
  headline,
  description,
  link,
  images,
  brandComponentTheme,
  textAlign = 'center',
}: BrandFilmstripProps) => {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <>
      <div className={cx('container bg', `text-${textAlign}`)} data-testid="filmstrip">
        {headline ? (
          <HeadingLevel
            headingLevelFallback={2}
            className={cx('heading-2xl xl:heading-4xl pb-2 xl:pb-4', {
              '!text-text-inverse': isDark,
              '!filmstrip-headline-text-light': isLight,
            })}
          >
            {headline}
          </HeadingLevel>
        ) : null}
        {description ? (
          <p
            className={cx('text-text text-base md:text-lg xl:text-xl font-normal pb-4 xl:pb-8', {
              'text-text-inverse': isDark,
              '!filmstrip-description-text-light': isLight,
            })}
          >
            {description}
          </p>
        ) : null}
      </div>
      <Filmstrip images={images} />
      <div className={cx('container overflow-hidden px-4 sm:px-10 bg text-center')}>
        {link ? (
          <BrandLink
            url={link.url}
            label={link.label}
            isNewWindow={link.isNewWindow}
            showNewWindowIcon={link.isNewWindow}
            brandComponentTheme={brandComponentTheme}
            variant="solid"
          />
        ) : null}
      </div>
    </>
  );
};
