import { useRect } from '@dx-ui/utilities-use-rect';
import cx from 'classnames';
import * as React from 'react';
import { usePopup } from './popup.context';
import { getPopupStyles } from './popup.utils';
import { useForkedRef } from '@dx-ui/utilities-use-forked-ref';

export type PopupContentPropsBase = { popupStyles?: React.CSSProperties; popupOnLeft?: boolean };

const PopupContent = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLDivElement> & PopupContentPropsBase
>(({ children, className, style, popupStyles = {}, popupOnLeft, ...rest }, forwardedRef) => {
  const { buttonRef, buttonClickedRef, popoverRef, popupId, isOpen, setOpen, closePopup } =
    usePopup();
  const ref = useForkedRef(forwardedRef, popoverRef);

  React.useEffect(() => {
    const popoverRefCurrent = popoverRef?.current;
    const onMouseDown = (e: MouseEvent) => {
      if (buttonClickedRef.current) {
        buttonClickedRef.current = false;
      } else if (isOpen && popoverRefCurrent && !popoverRefCurrent.contains(e.target as Element)) {
        setOpen(false);
      }
    };
    const onKeyDown = (e: KeyboardEvent) => {
      if (isOpen) {
        //parent elements such as <Dialog /> may have event listeners for keydown. Make sure we dont bubble the event back up
        e.stopPropagation();
        switch (e.key) {
          case 'Enter':
          case ' ':
            if (buttonClickedRef.current) {
              buttonClickedRef.current = false;
            } else if (
              isOpen &&
              popoverRefCurrent &&
              !popoverRefCurrent.contains(e.target as Element)
            ) {
              setOpen(false);
            }
            break;
          case 'Escape':
            closePopup();
            break;
          case 'Tab':
            requestAnimationFrame(() => {
              if (popoverRefCurrent && !popoverRefCurrent.contains(document.activeElement)) {
                setOpen(false);
              }
            });
            break;
          default:
            break;
        }
      }
    };
    if (popoverRefCurrent) {
      document.addEventListener('mousedown', onMouseDown);
      document.addEventListener('keydown', onKeyDown);
    }
    return () => {
      if (popoverRefCurrent) {
        document.removeEventListener('mousedown', onMouseDown);
        document.removeEventListener('keydown', onKeyDown);
      }
    };
  }, [buttonClickedRef, buttonRef, closePopup, isOpen, popoverRef, setOpen]);

  const popoverRect = useRect({ ref: popoverRef });
  const targetRect = useRect({ ref: buttonRef });
  const styles = getPopupStyles(targetRect, popoverRect, popupOnLeft);

  return (
    <div
      id={popupId}
      data-testid="popup-content"
      ref={ref}
      className={cx(
        'bg-bg border-border fixed z-50 mt-1 rounded border border-solid p-2 shadow-lg brand-ou:bg-secondary',
        {
          block: isOpen,
          hidden: !isOpen,
        },
        className
      )}
      style={{ ...style, ...styles, ...popupStyles }}
      {...rest}
    >
      {children}
    </div>
  );
});

PopupContent.displayName = 'PopupContent';

export { PopupContent };
export default PopupContent;
