import { useRouter } from 'next/router';
import type { CpmMappedComponentData } from '@dx-ui/cpm-sdk';
import {
  BrandComponentThemeInline,
  getBrandRouteParams,
  useCorporateAccount,
  Goals,
  useBrandData,
} from '@dx-ui/cpm-mapping-shared';
import { LocationProvider } from '@dx-ui/framework-location-provider';
import { sendReward } from '@dx-ui/framework-conductrics';
import { BrandsSearch, type FormData } from '@dx-ui/osc-brands-search';
import type { SearchUrlParameters } from '../../utils/search';
import { generateUrl } from '../../utils/search';
import { getVisitorId } from '../../utils/visitor-id';
import { mapDataToSpecialRates } from '../../utils/map-data-to-special-rates';
import { useSingleInstanceQueryStrings } from '../../hooks/use-query-strings';
import { getBaseUrl, isBrowser } from '../../utils/constants';
import { env } from '@dx-ui/framework-env';

const DEFAULT_LANGUAGE = 'en';

function handleSearchRedirect(searchUrlParameters: SearchUrlParameters) {
  const deepLinkUrl = generateUrl(searchUrlParameters);

  if (isBrowser && deepLinkUrl) {
    window.location.assign(deepLinkUrl);
  }
}

/*
 * SearchWidgetCpm is more featureful that most mapping components.
 *
 * As well as connecting CMS data and analytics callbacks it is also responsible
 * for re-writing the search redirect URL on form submission.
 *
 * Currently, there is a specific URL override for
 *
 * - Group Searches
 * - Flex Dates (Conductrics)
 *
 * and this list may grow in the future
 */
export default function SearchWidgetCpm({
  componentParams,
  mappedPage: { brandCode },
  isCPMEditor,
}: CpmMappedComponentData<'Search Widget', null>) {
  const { displayOption } = componentParams;
  const isGroupSearch = displayOption === 'group';
  const router = useRouter();
  const contentParam = Array.isArray(router.query?.content)
    ? router.query?.content[0]
    : router.query?.content;
  const languageCode = contentParam || router?.locale || DEFAULT_LANGUAGE;
  const corporateAccount = useCorporateAccount();
  const baseAppUrl = getBaseUrl(`${languageCode}${router.asPath}`);

  const { brandSlug, brandPath, auxPageSlug } = getBrandRouteParams(router.asPath, languageCode);
  const isLiveMode = !isCPMEditor;

  const sessionId = getVisitorId();
  const { isPartnerBrand, shopAvailOptions } = useBrandData();
  const maxNumRooms = shopAvailOptions?.maxNumRooms ?? 9;

  const {
    cid = '',
    awc = '',
    dclid = '',
    gclid = '',
    wtmcid = '',
  } = useSingleInstanceQueryStrings();
  const isDayUse = brandPath === 'workspaces-by-hilton';

  const onSearchSubmit = (formData: FormData, url: string) => {
    sendReward(Goals.FindAHotelClick);
    setTimeout(
      () =>
        handleSearchRedirect({
          formData,
          awc,
          cid,
          dclid,
          gclid,
          isGroupSearch,
          brandCode,
          languageCode,
          url,
          wtmcid,
          baseAppUrl,
        }),
      0
    );
  };

  const { additionalQS, specialRates } = mapDataToSpecialRates(componentParams, corporateAccount);

  return (
    <LocationProvider api={env('LOCATION_PROVIDER_API')}>
      <BrandComponentThemeInline componentParams={componentParams} brandCode={brandCode}>
        {/*
         * Add a key to the search component in Editor mode to force a rerender
         * when special rates have changed. This is to fix a memorisation bug in the
         * Search component in dx-ui.
         */}
        <BrandsSearch
          isPartnerBrand={isPartnerBrand ?? false}
          brandCode={brandCode}
          brandSlug={brandSlug}
          baseAppUrl={baseAppUrl}
          auxPageSlug={auxPageSlug}
          isBrandRoute={true}
          fullBrandPath={brandPath}
          key={(!isLiveMode && JSON.stringify(specialRates)) || undefined}
          isGroupSearch={isGroupSearch}
          isDayUse={isDayUse}
          defaultLanguage={DEFAULT_LANGUAGE}
          languageCode={languageCode}
          autocompleteUri={env('DX_AUTOCOMPLETE_URI')}
          additionalQSParameters={additionalQS}
          specialRates={specialRates}
          sessionId={sessionId ?? ''}
          onSearchSubmit={onSearchSubmit}
          maxNumRooms={maxNumRooms}
        />
      </BrandComponentThemeInline>
    </LocationProvider>
  );
}
