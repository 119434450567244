import type { AttachedContent } from './BrandCarouselAttachedContent';
import cx from 'classnames';

type CarouselItem = AttachedContent & { $ref: string };

interface BrandCarouselEditorButtons {
  isCPMEditor?: boolean;
  items: CarouselItem[];
  slideIndex: number;
}

/**
 * The BrandCarousel removes DOM nodes when the slide changes.
 * Bloomreach editor buttons don't update when this happens,
 * so we need to manually show/hide them based on the slide index.
 */
export function BrandCarouselEditorButtons({
  isCPMEditor,
  items,
  slideIndex,
}: BrandCarouselEditorButtons) {
  // No need to render anything if we aren't in the editor
  if (!isCPMEditor) {
    return null;
  }

  return (
    <div className="relative">
      <div
        className="absolute -top-12 right-0 h-10 w-full"
        data-testid="BrandCarouselEditorButtons"
      >
        {items.map((item, index) => {
          if (item.cmsDocumentControl) {
            return (
              <div
                key={item.$ref}
                data-testid={`BrandCarouselEditorButton-${index}`}
                className={cx('absolute w-10 h-[2.5rem] top-0', {
                  'right-0': index === slideIndex,
                  'left-[-10000px]': index !== slideIndex,
                })}
              >
                {item.cmsDocumentControl}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}
