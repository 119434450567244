import cx from 'classnames';
import Image from 'next/image';
import { Parallax } from 'react-scroll-parallax';
import type { TResponsiveImage } from '@dx-ui/osc-responsive-image';
import { ResponsiveImage, BREAKPOINTS } from '@dx-ui/osc-responsive-image';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import type { CaptionProps } from '@dx-ui/osc-caption';
import { useWindowSize, useIntersectionObserver } from 'usehooks-ts';
import { getIsReducedMotion } from '@dx-ui/utilities-accessibility';
import { HeadingLevel } from '@dx-ui/osc-heading-level';

export type OmittedImageProps = Omit<TResponsiveImage, 'aspectRatio' | 'width'>;

/**
 * Collage Props
 *
 * CollageProps extends the CollageContentProps, which is where the majority of the
 * props for this component are defined.
 */
export type CollageProps = {
  brandComponentTheme?: CmsBrandComponentTheme;
  /**
   * Logo above text content
   */
  logo?: OmittedImageProps;
  /**
   * Text rendered in headline, approx 20 characters
   */
  headline?: string;
  /**
   * Text rendered in copy paragraph
   */
  shortDescription?: string;
  /**
   * Text rendered in copy paragraph, approx 300 characters
   */
  longDescription?: string;
  /**
   * JSON that configures how the cta is rendered, label - approx 20 characters
   */
  cta?: {
    url?: string;
    text?: string;
    adaDescription?: string;
    isNewWindow?: boolean;
  };
  /**
   * Controls the layout direction
   */
  isFlipped?: boolean;
  /**
   * JSON that configures how the images are rendered
   */
  primaryImageMobile: OmittedImageProps;
  primaryImageDesktop: OmittedImageProps;
  primaryCaptionData?: CaptionProps;
  secondaryImage: OmittedImageProps;
  secondaryCaptionData?: CaptionProps;
  speed?: number;

  /**
   * By default the collage is not animated
   */
  isAnimated?: boolean;

  /**
   * CMS document editor button
   */
  cmsDocumentControl?: React.ReactNode;

  /**
   * Translation CSS classes to add to each collage item
   */
  cmsTranslationClasses?: string;
};

const PrimaryImage = ({
  primaryImageMobile,
  primaryImageDesktop,
  primaryCaptionData,
}: {
  primaryImageMobile: OmittedImageProps;
  primaryImageDesktop: OmittedImageProps;
  primaryCaptionData?: CaptionProps;
}) => {
  return (
    <>
      <div className="block lg:hidden">
        <ResponsiveImage
          altText={primaryImageMobile.altText}
          className="block w-full lg:hidden"
          width={820}
          imageUrl={primaryImageMobile.imageUrl}
          aspectRatio="3:2"
          captionData={primaryCaptionData}
        />
      </div>
      <div className="hidden lg:block">
        <ResponsiveImage
          altText={primaryImageDesktop.altText}
          className="hidden w-full lg:block"
          width={820}
          imageUrl={primaryImageDesktop.imageUrl}
          aspectRatio="1:1"
          captionData={primaryCaptionData}
        />
      </div>
    </>
  );
};

/**
 * The collage component is a collage of two images with additional content. There is a main image with a smaller image offset to the lower left or right corner
 * and content that can be flipped from left to right of the main image. On mobile the collage appears as a single image (main image) with additional content underneath.
 */
export const Collage: React.FC<CollageProps> = ({
  brandComponentTheme,
  logo,
  headline,
  shortDescription,
  longDescription,
  cta,
  primaryImageDesktop,
  primaryImageMobile,
  primaryCaptionData,
  secondaryImage,
  secondaryCaptionData,
  isFlipped = false,
  speed = -10,
  isAnimated = false,
  cmsDocumentControl,
  cmsTranslationClasses,
}) => {
  const { ref: intersectionRef, isIntersecting: inView } = useIntersectionObserver({
    threshold: [0.15],
    freezeOnceVisible: true,
  });
  const observedWrapper = !getIsReducedMotion() && isAnimated ? intersectionRef : null;
  const { width: screenWidth = 0 } = useWindowSize();
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <section
      className={cx(
        'container-fluid flex flex-col px-0 py-12 lg:container md:max-w-[1024px] lg:max-w-[1150px] lg:flex-row lg:items-center lg:justify-center relative',
        cmsTranslationClasses,
        {
          'brand-ou:bg-secondary': !isDark && !isLight,
          'bg-bg-dark brand-ol:bg-bg': isDark,
          'bg-bg-light brand-ol:bg-bg': isLight,
          'lg:pb-40': isAnimated,
        }
      )}
      data-testid="collageContainer"
    >
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      {isFlipped ? (
        <div className="block w-full lg:min-w-[640px]">
          <Parallax speed={speed} disabled={screenWidth < BREAKPOINTS['lg'] || !isAnimated}>
            <PrimaryImage
              primaryImageDesktop={primaryImageDesktop}
              primaryImageMobile={primaryImageMobile}
              primaryCaptionData={primaryCaptionData}
            />
          </Parallax>
        </div>
      ) : null}

      {!isFlipped ? (
        <div className="flex w-full flex-initial justify-end md:min-w-max lg:mx-auto lg:hidden lg:h-auto lg:max-w-[820px]">
          <div className="block w-full">
            <PrimaryImage
              primaryImageDesktop={primaryImageDesktop}
              primaryImageMobile={primaryImageMobile}
              primaryCaptionData={primaryCaptionData}
            />
          </div>
        </div>
      ) : null}

      <div
        ref={observedWrapper}
        className={cx(
          'mx-2 my-4 grow items-start justify-start px-2 lg:min-w-[400px] lg:px-0',
          isFlipped ? 'lg:-me-8 lg:ms-2' : 'lg:-me-8 lg:ms-2'
        )}
      >
        <div
          data-testid="testBoxBgColor"
          className={cx(
            'block w-full p-8 py-8 xl:max-w-full',
            isFlipped ? 'lg:pe-8 lg:ps-16' : 'lg:ps-8 lg:pe-16',
            {
              'bg-bg': !isDark && !isLight,
              'brand-ol:bg-primary': isDark,
              'brand-ol:bg-bg-light': isLight,
            }
          )}
        >
          {logo ? (
            <div className="relative flex aspect-[3/2] max-h-40">
              <Image
                className={cx('object-contain', {
                  'opacity-100': inView && isAnimated,
                  'duration-1000 ease-in-out transition-all motion-reduce:transition-none opacity-0 motion-reduce:opacity-100':
                    isAnimated,
                })}
                data-testid="collageLogo"
                fill
                src={logo.imageUrl}
                alt={logo.altText}
              />
            </div>
          ) : null}

          {/* headline */}
          {headline ? (
            <HeadingLevel
              headingLevelFallback={2}
              className={cx(
                'heading-2xl lg:mb-2 lg:heading-4xl my-2 motion-reduce:translate-y-2 start-10',
                {
                  'delay-75 opacity-100 translate-y-2': inView && isAnimated,
                  'translate-y-4': !inView && isAnimated,
                  'duration-1000 ease-in-out transition-all motion-reduce:transition-none opacity-0 motion-reduce:opacity-100':
                    isAnimated,
                  '!text-text-inverse': isDark,
                  'brand-ht:!text-text-inverse': isLight,
                }
              )}
            >
              {headline}
            </HeadingLevel>
          ) : null}

          {/* copy */}
          {longDescription ? (
            <BrandTextBody
              className={cx('my-2 motion-reduce:translate-y-0', {
                'delay-200 translate-y-0 opacity-100': inView && isAnimated,
                'translate-y-4': !inView && isAnimated,
                'duration-1000 ease-in-out transition-all motion-reduce:transition-none opacity-0 motion-reduce:opacity-100':
                  isAnimated,
                'text-text-inverse': isDark,
                'brand-ht:!text-text-inverse': isLight,
              })}
              brandComponentTheme={brandComponentTheme}
            >
              {longDescription}
            </BrandTextBody>
          ) : null}

          {shortDescription ? (
            <BrandTextBody
              className={cx('my-2 text-base motion-reduce:translate-y-0', {
                'delay-300 translate-y-0 opacity-100': inView && isAnimated,
                'translate-y-4': !inView && isAnimated,
                'duration-1000 ease-in-out transition-all motion-reduce:transition-none opacity-0 motion-reduce:opacity-100':
                  isAnimated,
                'text-text-inverse': isDark,
                'brand-ht:!text-text-inverse': isLight,
              })}
              brandComponentTheme={brandComponentTheme}
            >
              {shortDescription}
            </BrandTextBody>
          ) : null}

          {/* cta */}
          {cta?.url ? (
            <div
              className={cx({
                'delay-500 translate-y-0 opacity-100': inView && isAnimated,
                'translate-y-4': !inView && isAnimated,
                'duration-1000 ease-in-out transition-all motion-reduce:transition-none opacity-0 motion-reduce:opacity-100':
                  isAnimated,
              })}
            >
              <BrandLink
                data-testid="collageCtaButton"
                url={cta.url}
                label={cta.text}
                isNewWindow={cta.isNewWindow}
                showNewWindowIcon={cta.isNewWindow}
                brandComponentTheme={brandComponentTheme}
              />
            </div>
          ) : null}
        </div>

        {/* image desktop only */}
        <div
          className={`relative z-20 -mt-5 hidden w-full lg:block ${
            isFlipped ? 'end-20' : 'start-20'
          }`}
          data-testid="image-container"
          style={{
            boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.25)',
          }}
        >
          <div
            className={cx(`bg-bg block p-4 shadow-lg brand-ou:bg-bg-light brand-ey:bg-bg-light`, {
              'brand-ou:bg-secondary': isLight || isDark,
            })}
          >
            <ResponsiveImage
              altText={secondaryImage.altText}
              className="max-w-lg"
              width={650}
              imageUrl={secondaryImage.imageUrl}
              aspectRatio="16:9"
              captionData={secondaryCaptionData}
            />
          </div>
        </div>
      </div>

      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      {!isFlipped ? (
        <div className="flex w-full flex-initial justify-end md:min-w-max lg:mx-auto lg:h-auto lg:max-w-[820px]">
          <div className="hidden w-full lg:block lg:min-w-[640px]">
            <Parallax speed={speed} disabled={screenWidth < BREAKPOINTS['lg'] || !isAnimated}>
              <PrimaryImage
                primaryImageDesktop={primaryImageDesktop}
                primaryImageMobile={primaryImageMobile}
                primaryCaptionData={primaryCaptionData}
              />
            </Parallax>
          </div>
        </div>
      ) : null}
      {cmsDocumentControl}
    </section>
  );
};

export default Collage;
