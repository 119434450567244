import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import { Spacer } from '@dx-ui/osc-spacer';
import { LivSmartAccent } from './illustrations/livsmart-accent';
import { LivSmartFurniture } from './illustrations/livsmart-furniture';
import { HamptonSignatureDefault } from './illustrations/hampton-signature-default';
import { HamptonSignatureLight } from './illustrations/hampton-signature-light';
import { HamptonSignatureDark } from './illustrations/hampton-signature-dark';
import { EnterpriseFiligreeDefault } from './illustrations/enterprise-filigree-default';
import { EnterpriseFiligreeLight } from './illustrations/enterprise-filigree-light';
import { EnterpriseFiligreeDark } from './illustrations/enterprise-filigree-dark';

export default createCpmComponentDefinition(
  'Illustration Block',

  function mapComponentData({ data }) {
    return data;
  },

  function IllustrationBlock({ componentParams }) {
    if (componentParams.illustration) {
      switch (componentParams.illustration) {
        case 'ey-accent':
          return (
            <Spacer>
              <LivSmartAccent />
            </Spacer>
          );

        case 'ey-furniture':
          return (
            <Spacer>
              <LivSmartFurniture />
            </Spacer>
          );

        case 'hx-signature-default':
          return (
            <Spacer>
              <HamptonSignatureDefault />
            </Spacer>
          );

        case 'hx-signature-light':
          return (
            <Spacer>
              <HamptonSignatureLight />
            </Spacer>
          );

        case 'hx-signature-dark':
          return (
            <Spacer>
              <HamptonSignatureDark />
            </Spacer>
          );

        case 'ww-filigree-default':
          return (
            <Spacer>
              <EnterpriseFiligreeDefault />
            </Spacer>
          );

        case 'ww-filigree-light':
          return (
            <Spacer>
              <EnterpriseFiligreeLight />
            </Spacer>
          );

        case 'ww-filigree-dark':
          return (
            <Spacer>
              <EnterpriseFiligreeDark />
            </Spacer>
          );

        case 'none':
          return null;

        default:
          return null;
      }
    }
  }
);
