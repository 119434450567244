import cx from 'classnames';

type StatsProps = {
  data: Array<{
    heading: string;
    description: string;
  }>;
  brandComponentTheme?: CmsBrandComponentTheme;
};

export const Stats = ({ data, brandComponentTheme }: StatsProps) => {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  return (
    <div className="flex justify-center ps-8">
      <ul className="flex flex-row flex-wrap gap-y-5">
        {data.map((stat) => (
          <li key={stat?.description} className="basis-1/2 pe-5">
            <span
              className={cx('text-primary text-5xl font-bold block', {
                'text-text-inverse': isDark,
                'brand-ht:text-text-inverse': isLight,
              })}
            >
              {stat?.heading}
            </span>
            <span
              className={cx('text-base block', {
                'text-text-inverse': isDark,
                'brand-ht:text-text-inverse': isLight,
              })}
            >
              {stat?.description}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Stats;
