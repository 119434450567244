export const LivSmartAccent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="auto"
    viewBox="0 0 1440 52"
    fill="none"
    data-testid="livsmart-accent"
    aria-hidden="true"
  >
    <g>
      <path
        fill="#FF9939"
        d="M694.558 774.967H-331.77c-4.966 0-8.993-4.045-8.993-9.034v-1189.45c0-4.989 4.027-9.035 8.993-9.035H694.558c4.966 0 8.993 4.046 8.993 9.035v1189.45c.01 4.989-4.027 9.034-8.993 9.034Z"
      />
      <mask id="accent_b" width="1045" height="1208" x="-341" y="-433" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          d="M694.558 774.967H-331.77c-4.966 0-8.993-4.045-8.993-9.034v-1189.45c0-4.989 4.027-9.035 8.993-9.035H694.558c4.966 0 8.993 4.046 8.993 9.035v1189.45c.01 4.989-4.027 9.034-8.993 9.034Z"
        />
      </mask>
      <g mask="url(#accent_b)">
        <path fill="#F35A20" d="M696.456-75.62h-839.527v594.012h839.527V-75.621Z" />
      </g>
      <mask id="accent_c" width="1045" height="1208" x="-341" y="-433" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          d="M694.558 774.967H-331.77c-4.966 0-8.993-4.045-8.993-9.034v-1189.45c0-4.989 4.027-9.035 8.993-9.035H694.558c4.966 0 8.993 4.046 8.993 9.035v1189.45c.01 4.989-4.027 9.034-8.993 9.034Z"
        />
      </mask>
      <g mask="url(#accent_c)">
        <path
          fill="#FFFAF2"
          d="M309.175 477.033h41.761a35.245 35.245 0 0 0 24.963-10.38l3.158-3.172c11.842-11.886 13.75-30.487 4.567-44.551L317.43 317.52a35.6 35.6 0 0 1-4.887-27.405l67.253-293.85a35.646 35.646 0 0 0 .46-13.531l-1.719-10.812c-2.378-14.977-13.97-26.783-28.84-29.373l-21.925-3.814a35.214 35.214 0 0 0-31.618 10.49l-56.95 60.09a35.267 35.267 0 0 1-21.925 10.822L88.939 33.56a35.277 35.277 0 0 1-22.365-5.19L10.643-6.706A35.441 35.441 0 0 1 .61-16.172l-29.46-41.42c-11.362-15.98-33.487-19.645-49.356-8.18l-1.818 1.314A35.319 35.319 0 0 0-90.637-52.21l-15.909 30.267a35.577 35.577 0 0 0-3.248 24.213l55.951 254.307a35.687 35.687 0 0 1-1.109 19.264l-55.691 161.611a35.568 35.568 0 0 0-1.939 11.604c0 21.563 18.997 38.147 40.252 35.135l31.828-4.517a35.081 35.081 0 0 1 4.707-.351l344.97-2.289Z"
        />
      </g>
      <mask id="accent_d" width="1045" height="1208" x="-341" y="-433" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          d="M694.558 774.967H-331.77c-4.966 0-8.993-4.045-8.993-9.034v-1189.45c0-4.989 4.027-9.035 8.993-9.035H694.558c4.966 0 8.993 4.046 8.993 9.035v1189.45c.01 4.989-4.027 9.034-8.993 9.034Z"
        />
      </mask>
      <g mask="url(#accent_d)">
        <path
          fill="#4A583B"
          d="M745.242-26.602c0-27.074-21.844-49.019-48.796-49.019-26.951 0-48.796 21.945-48.796 49.019v152.757h97.582V-26.602h.01Z"
        />
      </g>
      <mask id="accent_e" width="1045" height="1208" x="-341" y="-433" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          d="M694.558 774.967H-331.77c-4.966 0-8.993-4.045-8.993-9.034v-1189.45c0-4.989 4.027-9.035 8.993-9.035H694.558c4.966 0 8.993 4.046 8.993 9.035v1189.45c.01 4.989-4.027 9.034-8.993 9.034Z"
        />
      </mask>
      <g mask="url(#accent_e)">
        <path
          fill="#4A583B"
          d="M-20.116 315.773c1.26-67.208 12.451-134.697 36.624-197.539C31.889 77.769 52.703 40.576 77.776 6.756c1.769-2.39 3.238-4.949 4.357-7.71 18.647-46.016 82.663-183.404 202.609-242.662v211.954c-76.507 35.276-142.131 44.13-178.356 46.027-11.662.613-22.364 6.786-28.56 16.734C25.352 115.344.65 216.11 2.818 315.773h-22.934Z"
        />
      </g>
    </g>
    <g>
      <path
        fill="#4A583B"
        d="M1424.41 429.028H406.957c-12.731 0-23.054-10.37-23.054-23.159L311.614-777.587c0-12.79 82.602-23.159 95.333-23.159H1424.41c12.73 0 23.05 10.369 23.05 23.159V405.869c0 12.789-10.32 23.159-23.05 23.159Z"
      />
      <mask id="accent_g" width="1137" height="1231" x="311" y="-801" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          d="M1424.41 429.028H406.957c-12.731 0-23.054-10.37-23.054-23.159L311.614-777.587c0-12.79 82.602-23.159 95.333-23.159H1424.41c12.73 0 23.05 10.369 23.05 23.159V405.869c0 12.789-10.32 23.159-23.05 23.159Z"
        />
      </mask>
      <g mask="url(#accent_g)">
        <path fill="#FF9939" d="M1113.73-558.235H-805.461v672.554H1113.73v-672.554Z" />
        <path
          fill="#F35A20"
          d="M1512.33 227.113c-8.47-172.694-29.78-352.766-54.47-524.164-10.98-74.898-22.81-149.665-35.33-224.312-1.22-7.298-.19-14.797 2.84-21.543 20.25-45.063 35.8-89.514 47.3-133.161 0-.061.01-.121.01-.181-13.25-82.909-14.28-184.78 16.58-298.336.93-3.434 1.92-6.877 2.91-10.33-4.41-34.066-10.78-67.176-18.75-99.146-60.14-241.08-209.05-418.81-264.18-478.33-8.49-9.18-23.75-4.33-25.57 8.06-86.55 589.503 146.82 928.596 214.17 1012.318 4.16 5.17 6.83 11.333 7.8 17.908 11.32 76.504 21.9 153.119 31.61 229.853 21.19 170.897 38.87 350.728 43.91 522.528l1.3 104.572h33.28l-3.41-105.736ZM-886.484-637.248v751.567h81.013v-672.554H1069.32a855.22 855.22 0 0 1-31.55-79.013H-886.484Z"
        />
        <path
          fill="#FF9939"
          d="M1378-213.229c-3.25-7.439-4.72-15.51-4.21-23.621 5.85-91.652-.17-431.317-376.992-660.869-3.568-2.168-8.185.612-7.945 4.788 5.546 96.792 23.557 181.688 48.917 255.673h156.97v274.384c61.64 71.173 121.14 114.701 152.92 135.25 8.7 5.621 15.53 13.702 19.48 23.309 74.19 180.644 125.19 367.321 125.32 537.345h25.8c-3.83-174.31-60.28-363.878-140.26-546.259Z"
        />
        <path
          fill="#F35A20"
          d="M1113.73 114.319h81.02V-14.395c-25.99-11.986-53.23-27.024-81.02-45.877V114.32Z"
        />
        <path
          fill="#88AED6"
          d="M1499.61 333.04c-1.26-67.209-12.45-134.698-36.63-197.539-15.39-40.466-36.19-77.659-61.26-111.479-1.77-2.389-3.24-4.949-4.36-7.71-18.65-46.016-82.66-183.404-202.61-242.662v211.955c76.51 35.275 142.13 44.13 178.36 46.027 11.66.612 22.36 6.786 28.56 16.734 52.48 84.234 77.18 185.001 75 284.674h22.94Z"
        />
        <path
          fill="#FFFAF2"
          d="M1018.35-248.133c.92-.181 1.82-.342 2.73-.512l6.37-21.152c1.09-3.634 1.26-7.388.49-11.072l-1.85-8.844c-2.56-12.257-15.02-21.914-31.031-24.032l-23.604-3.132c-12.501-1.657-25.262 1.566-34.006 8.583l-61.258 49.168c-6.235 5-14.589 8.142-23.583 8.854l-481.696 10.983c-8.414.672-16.878-.824-24.053-4.247l-60.158-28.71c-4.227-2.018-7.895-4.648-10.793-7.75l-31.688-33.89c-12.221-13.07-36.025-16.072-53.093-6.696l-1.958 1.074c-4.767 2.62-8.674 6.054-11.422 10.019l-17.109 24.765c-4.197 6.083-5.436 13.1-3.497 19.816l60.188 208.1c1.519 5.24 1.099 10.69-1.199 15.76L156.221 91.21c-1.379 3.052-2.088 6.265-2.088 9.497 0 17.648 20.436 31.22 43.3 28.75l34.246-3.694a49.951 49.951 0 0 1 5.057-.281l714.723-1.867h44.929c10.072 0 19.722-3.052 26.852-8.493l3.4-2.59c12.74-9.727 14.79-24.945 4.91-36.46L960.343-6.917c-5.676-6.615-7.575-14.716-5.256-22.426l40.582-134.888c-16.769-18.682-33.367-39.13-49.626-61.536 25.262-10.531 49.346-17.809 72.307-22.366Z"
        />
        <path
          fill="#6A3D57"
          fillRule="evenodd"
          d="M409.935-12.86c-34.985-4.226-40.901-11.122-36.195 30.488 9.534 86.111 9.534 179.349 0 265.47-4.706 41.84 1.21 34.713 36.195 30.708 109.064-12.91 225.973-12.91 335.048 0 34.985 4.005 40.901 11.122 36.194-30.708-9.533-86.111-9.533-179.359 0-265.47 4.707-41.62-1.209-34.714-36.194-30.487-109.075 12.688-225.984 12.688-335.048 0Z"
          clipRule="evenodd"
        />
      </g>
    </g>
    <g>
      <path fill="#FFFAF2" d="M366.775-55.122h-22.284V66.757h22.284V-55.123Z" />
    </g>
  </svg>
);
