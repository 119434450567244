import { useCallback } from 'react';
import { BrandShowcase } from '@dx-ui/osc-marketing';
import { extractInstanceIndexFromId } from '../../utils/id-transformer';
import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';

export default createCpmComponentDefinition(
  'Brand Showcase',

  function mapComponentData({ data }) {
    const { documentBrandCode, name, shortDescription } = data;

    return {
      code: documentBrandCode,
      name: name ?? '',
      shortDescription,
      label: data.link?.label ?? '',
      url: data.link?.url ?? '',
    };
  },

  function BrandShowcaseCpm({
    items = [],
    componentParams,
    listData,
    metrics,
    mappedPage: { brandCode },
  }) {
    const id = listData?.id ?? '';

    const onItemClicked = useCallback(
      (index: number) => {
        if (metrics) {
          const { name } = items[index] ?? {};
          metrics.trackBrandShowcaseClick?.([
            {
              brandShowcaseNumber: extractInstanceIndexFromId(id),
              itemNumber: index + 1,
              totalItems: items.length,
              brandName: name ?? '',
            },
          ]);
        }
      },
      [metrics, id, items]
    );

    if (!items.length) {
      return null;
    }

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <BrandShowcase
          id={id}
          onItemClicked={onItemClicked}
          items={items}
          /*these should be hard coded (according to dx-hotels-ui/src/components/brand-showcase/index.tsx)*/
          logoUrl="/modules/assets/svgs/logos/brand"
          baseUrl="/en"
        />
      </BrandComponentThemeInline>
    );
  }
);
