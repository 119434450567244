import { Pagination } from '@dx-ui/osc-pagination';
import { useCycle } from '@dx-ui/osc-cycle';

const ImageHeadlinerControls = ({ label = 'Page', hideLabel = true, loop = true }) => {
  const { hasSlides, cycleId, active, total, onNextClick, onPreviousClick } = useCycle();
  return hasSlides ? (
    <div
      data-e2e="arrows"
      className="pointer-events-none inset-0 mr-4 justify-end whitespace-nowrap text-xl font-bold lg:text-2xl"
    >
      <Pagination
        controls={cycleId}
        label={label}
        hideLabel={hideLabel}
        current={active}
        total={total}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
        loop={loop}
        // TODO: bring this prop back once OSC-1278 is complete
        // // isHeadliner={true}
      />
    </div>
  ) : null;
};

export { ImageHeadlinerControls };
export default ImageHeadlinerControls;
