import cx from 'classnames';

type TimelineProps = {
  data: Array<{
    heading: string;
    description: string;
  }>;
  brandComponentTheme?: CmsBrandComponentTheme;
};

export const Timeline = ({ data, brandComponentTheme }: TimelineProps) => {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  return (
    <ol className="ms-6">
      {data.map((timeline, index) => (
        <li
          className={cx(
            "relative flex items-center after:h-full after:content-[''] after:absolute after:border-l-2 after:border-primary before:bg-primary before:absolute before:h-2 before:w-2 before:rounded-full before:content-[''] before:top-1/2 before:-mt-1 before:-translate-x-[.17rem] rtl:before:translate-x-[.17rem]",
            {
              'after:bottom-1/2': index === data.length - 1,
              'after:top-1/2': index === 0,
              'after:border-text-inverse before:bg-text-inverse brand-ou:after:border-secondary brand-ou:before:bg-secondary':
                isDark,
              'after:brand-ht:border-text-inverse before:brand-ht:bg-text-inverse': isLight,
            }
          )}
          key={timeline?.description}
        >
          <div className="flex items-center py-4 ps-6">
            <span
              className={cx('text-primary me-4 text-3xl font-bold', {
                'text-text-inverse': isDark,
                'brand-ht:text-text-inverse': isLight,
              })}
            >
              {timeline?.heading}
            </span>
            <span
              className={cx('text-lg self-start', {
                'text-text-inverse': isDark,
                'brand-ht:text-text-inverse': isLight,
              })}
            >
              {timeline?.description}
            </span>
          </div>
        </li>
      ))}
    </ol>
  );
};

export default Timeline;
