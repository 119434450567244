import cx from 'classnames';

type TBrandTheme = {
  brandCodeForTheme: string;
  isStaticContentPage?: boolean;
  children: JSX.Element;
};

export const BrandThemeWrapper = ({ children, brandCodeForTheme, ...props }: TBrandTheme) => {
  const { isStaticContentPage } = props;
  return (
    <div
      id="BrandAppWrapper"
      className={cx('bg-bg text-text font-sans', {
        [`theme-${brandCodeForTheme?.toLowerCase()}`]: brandCodeForTheme && !isStaticContentPage,
        [`theme-${brandCodeForTheme?.toLowerCase()}-static`]:
          brandCodeForTheme && isStaticContentPage,
      })}
    >
      {children}
    </div>
  );
};
