import type { ReactNode } from 'react';
import type { Link } from '@dx-ui/osc-link';
import { useCycle } from '@dx-ui/osc-cycle';
import cx from 'classnames';
import Image from 'next/image';

export type TImageHeadlinerItem = {
  headline?: string;
  id?: string;
  link?: Link;
  imageAltText?: string;
  imageUrl?: string;
  isPortfolio?: boolean;
  index?: number;
  /** CMS document editor button **/
  cmsDocumentControl?: ReactNode;
  /* Translation CSS classes to add to each item */
  cmsTranslationClasses?: string;
};

/**
 * A headline item that can contain an image, headline text, and link to more information.
 */
export const ImageHeadlinerItem = (props: TImageHeadlinerItem) => {
  const { active, hasSlides } = useCycle();
  const {
    headline,
    id,
    link,
    imageUrl,
    imageAltText,
    index = 0,
    cmsDocumentControl,
    cmsTranslationClasses,
    ...rest
  } = props;
  const { adaDescription, isNewWindow, url } = (link || {}) as Link;
  const { label = '' } = (link || {}) as Link;
  const describedByID = `${id}-description`;

  const imageCss = 'inline-block max-h-12 md:max-h-16 w-16 md:w-24';

  const target = isNewWindow ? '_blank' : '_self';
  const rel = isNewWindow ? 'noopener' : null;

  return (
    <div
      data-testid="imageHeadlinerItem"
      {...rest}
      className={cx(
        'sm:mx my-2 mx-4 flex justify-center md:m-0 md:mx-auto md:w-4/5 md:px-4 lg:w-full lg:px-6',
        {
          hidden: hasSlides && index !== active,
        },
        cmsTranslationClasses
      )}
    >
      <span data-testid={describedByID} className="flex items-center text-left md:justify-center">
        {imageUrl && imageAltText && (
          <div data-testid="imageHeadlinerImage">
            <div className="relative mr-4 flex h-8 w-16 overflow-hidden md:mr-6 md:h-16 md:w-24">
              <Image
                id={id}
                className={cx(imageCss)}
                alt={imageAltText}
                src={imageUrl}
                style={{
                  objectFit: 'cover',
                }}
                fill
              />
            </div>
          </div>
        )}
        <span
          className="image-headliner-item-text lg:image-headliner-font-size-lg"
          aria-label={headline}
        >
          {headline}
          &nbsp;
          {url && label && target && (
            <a
              className="image-headliner-item-link inline-block overflow-visible underline sm:max-w-full md:inline md:max-w-none"
              href={url}
              aria-describedby={adaDescription ? describedByID : undefined}
              target={target}
              data-testid="imageHeaderlinerItemLink"
              rel={rel || undefined}
            >
              {label}
            </a>
          )}
        </span>
      </span>
      {cmsDocumentControl}
    </div>
  );
};

export default ImageHeadlinerItem;
