import { Offset } from '@dx-ui/osc-offset';
import type { TOffset } from '@dx-ui/osc-offset';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import cx from 'classnames';
import { HeadingLevel } from '@dx-ui/osc-heading-level';

export interface BrandOffsetProps extends TOffset {
  shortDescription?: string;
  brandComponentTheme?: CmsBrandComponentTheme;
  link?: {
    url: string;
    label: string;
    isNewWindow: boolean;
  };
}

/**
 * Brand Offset utilizes the OSC-Offset and adds a stylized headline, short description, and link.
 */
export const BrandOffset = ({
  image,
  headline,
  isInversePattern = false,
  link,
  shortDescription,
  brandComponentTheme,
}: BrandOffsetProps) => {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <div className="container">
      <Offset
        image={image}
        headline={
          headline && (
            <HeadingLevel
              headingLevelFallback={2}
              className={cx(
                'heading-2xl brand-es:lg:heading-4xl brand-ey:heading-3xl brand-gu:lg:heading-4xl brand-nd:sm:heading-4xl brand-wa:font-serif brand-wa:normal-case brand-lx:font-serif',
                {
                  '!text-text-inverse': isDark,
                  'brand-ht:!text-text-inverse': isLight,
                }
              )}
            >
              {headline}
            </HeadingLevel>
          )
        }
        isInversePattern={isInversePattern}
      >
        {shortDescription && (
          <BrandTextBody
            className={cx('text-text brand-wa:font-serif mb-3 py-2 text-sm md:text-base', {
              'text-text-inverse': isDark,
              'brand-ht:!text-text-inverse': isLight,
              'brand-ou:text-primary': !isDark,
            })}
            brandComponentTheme={brandComponentTheme}
          >
            {shortDescription}
          </BrandTextBody>
        )}
        {link?.url && (
          <BrandLink
            url={link?.url}
            label={link?.label}
            isNewWindow={link?.isNewWindow}
            showNewWindowIcon={link?.isNewWindow}
            brandComponentTheme={brandComponentTheme}
          />
        )}
      </Offset>
    </div>
  );
};

export default BrandOffset;
