import { extractInstanceIndexFromId } from '../../utils/id-transformer';
import { FullWidthImage } from '@dx-ui/osc-marketing';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import { useSegmentedItems } from '../../hooks/use-segments';

export default createCpmComponentDefinition(
  'Full Width Image',

  function mapData({ data }) {
    const asset = data.cpmAssets[0];

    const captionData = asset
      ? {
          captionLink: asset.captionLink,
          caption: asset.caption,
        }
      : undefined;

    return {
      $ref: data.ref?.$ref,
      id: data.id,
      description: data.shortDescription,
      imageOverlayButtonText: data.label,
      title: data.headline,
      link: {
        adaDescription: data.link?.adaDescription,
        isNewWindow: data.link?.isNewWindow,
        label: data.link?.label,
        url: data.link?.url ?? '',
      },
      desktopImageUrl: asset?.aspectRatios['18x5']?.url ?? '',
      tabletImageUrl: asset?.aspectRatios['18x5']?.url ?? '',
      mobileImageUrl: asset?.aspectRatios['3x2']?.url ?? '',
      captionData,
      imageAltText: asset?.altText ?? '',
      segmentIds: data.segmentIds,
    };
  },

  function FullWidthImageCpm({ items = [], componentParams, mappedPage: { brandCode }, metrics }) {
    const filteredItems = useSegmentedItems(items);

    const data = filteredItems[0];

    if (!data) {
      return null;
    }

    const { id = '', imageOverlayButtonText } = data;

    const overlayButtonClicked = () => {
      if (imageOverlayButtonText && metrics) {
        metrics.trackFullWidthImageClick?.([
          {
            fullWidthImageNumber: extractInstanceIndexFromId(id),
            label: imageOverlayButtonText,
          },
        ]);
      }
    };

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <FullWidthImage {...data} overlayButtonClicked={overlayButtonClicked} />
      </BrandComponentThemeInline>
    );
  }
);
