import type * as React from 'react';

export function isRightClick(nativeEvent: MouseEvent) {
  return nativeEvent.which === 3 || nativeEvent.button === 2;
}

export function focus<T extends HTMLElement = HTMLElement>(element: T | undefined | null) {
  element && element.focus();
}

export function getXStyles(
  targetRect: DOMRect,
  popoverRect: DOMRect,
  popupOnLeft?: boolean
): React.CSSProperties {
  const { directionRight, directionLeft, directionX, directionY } = getCollisions(
    targetRect,
    popoverRect
  );
  const xPosition = targetRect.right - popoverRect.width;
  const styles: React.CSSProperties = {};
  if (directionX && !directionY) {
    // we collide with left, right but not top, bottom, start at left, full-width
    styles.maxWidth = window.innerWidth;
    styles.overflowX = 'auto';
    styles.left = 0;
  } else if (directionX && directionY) {
    // we collide top, bottom, right, left - display in middle
    styles.maxWidth = window.innerWidth;
    styles.overflowX = 'auto';
    styles.left = '50%';
    styles.transform = 'translate(-50%, -50%)';
  } else if (directionY && (directionLeft || directionRight)) {
    // we collide top, bottom, (left or right) - display in middle, no maxWidth
    styles.overflowX = 'auto';
    styles.left = '50%';
    styles.transform = 'translate(-50%, -50%)';
  } else if (popupOnLeft && !popoverRect.width) {
    // avoids popover flashing on right on initial trigger
    styles.visibility = 'hidden';
  } else {
    // we collide with right or popupOnLeft - place on left, otherwise place on right
    styles.left = directionRight || popupOnLeft ? `${xPosition}px` : `${targetRect.left}px`;
  }
  return styles;
}

export function getYStyles(targetRect: DOMRect, popoverRect: DOMRect): React.CSSProperties {
  const { directionY, directionUp } = getCollisions(targetRect, popoverRect);
  const yPosition = targetRect.bottom;
  const styles: React.CSSProperties = {};
  if (directionY) {
    // we collide with top and bottom, display in the middle
    styles.maxHeight = window.innerHeight;
    styles.overflowY = 'auto';
    styles.top = '50%';
    styles.transform = 'translate(-50%, -50%)';
  } else {
    // we collide with bottom - place on top, otherwise bottom
    styles.top = directionUp ? `${targetRect.top - popoverRect.height}px` : `${yPosition}px`;
  }
  return styles;
}

export function getCollisions(
  targetRect: DOMRect,
  popoverRect: DOMRect,
  offsetLeft = 0,
  offsetBottom = 0
) {
  const collisions = {
    top: targetRect.top - popoverRect.height < 0,
    right: window.innerWidth < targetRect.left + popoverRect.width - offsetLeft,
    bottom: window.innerHeight < targetRect.bottom + popoverRect.height - offsetBottom,
    left: targetRect.left + targetRect.width - popoverRect.width < 0,
  };
  const directions = {
    directionRight: collisions.right && !collisions.left,
    directionLeft: collisions.left && !collisions.right,
    directionUp: collisions.bottom && !collisions.top,
    directionDown: collisions.top && !collisions.bottom,
    directionX: collisions.left && collisions.right,
    directionY: collisions.top && collisions.bottom,
  };
  return directions;
}

export function getPopupStyles(
  targetRect: DOMRect | null,
  popoverRect: DOMRect | null,
  popupOnLeft?: boolean
): React.CSSProperties {
  const styles: React.CSSProperties =
    targetRect && popoverRect
      ? {
          ...getXStyles(targetRect, popoverRect, popupOnLeft),
          ...getYStyles(targetRect, popoverRect),
        }
      : { visibility: 'hidden' };
  return styles;
}
