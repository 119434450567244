import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';
import Accordion from './Accordion';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { useSegmentedItems } from '../../hooks/use-segments';
import cx from 'classnames';
import { Markdown } from '@dx-ui/osc-markdown';
import { useRouter } from 'next/router';
import { Link } from '@dx-ui/osc-link';

interface FaqItem {
  question?: string;
  answerParagraph?: string[];
  answerOrderedList?: string[];
  answerUnOrderedList?: string[];
  link?: { linkUrl?: string; linkLabel?: string; adaDescription?: string; isNewWindow?: boolean }[];
}

interface FaqBlock {
  faq?: FaqItem[];
}

export interface AccordionContentBlock {
  heading?: string;
  description?: string;
  unorderedList?: string[];
  orderedList?: string[];
  links?: { linkUrl: string; linkLabel: string; adaDescription?: string; isNewWindow: boolean }[];
}

const remapFaqContentToDetails = (faqContent: FaqBlock[]) => {
  return faqContent.flatMap((block, blockIndex) =>
    Array.isArray(block.faq)
      ? block.faq.map((faqItem, faqIndex) => {
          const content: AccordionContentBlock[] = [];

          if (Array.isArray(faqItem.answerParagraph)) {
            faqItem.answerParagraph.forEach((paragraph) => {
              content.push({ description: paragraph });
            });
          }

          if (Array.isArray(faqItem.answerOrderedList)) {
            content.push({ orderedList: faqItem.answerOrderedList });
          }

          if (Array.isArray(faqItem.answerUnOrderedList)) {
            content.push({ unorderedList: faqItem.answerUnOrderedList });
          }

          if (Array.isArray(faqItem.link)) {
            content.push({
              links: faqItem.link.map((link) => ({
                linkUrl: link.linkUrl ?? '',
                linkLabel: link.linkLabel ?? '',
                adaDescription: link.adaDescription,
                isNewWindow: Boolean(link.isNewWindow),
              })),
            });
          }

          return {
            key: `faq_${blockIndex}_${faqIndex}`,
            collapsedButtonLabel: faqItem.question ?? '',
            expandedButtonLabel: faqItem.question ?? '',
            content,
          };
        })
      : []
  );
};

export default createCpmComponentDefinition(
  'Accordion',

  function mapComponentData({ data }) {
    return {
      key: `acc${data.id}`,
      segmentIds: data.segmentIds,
      collapsedButtonLabel: data.headline || data.label || data.title || '',
      expandedButtonLabel: data.headline || data.label || data.title || '',
      content: data.contentBlock,
      isFaq: data.contentType === 'dxcms:Faq',
      faqContent: data.subTopic ? remapFaqContentToDetails(data.subTopic) : [],
    };
  },

  function CpmAccordion({ items = [], listData, componentParams, mappedPage: { brandCode } }) {
    const filteredItems = useSegmentedItems(items || []);

    const isDark = componentParams.theme === 'dark';
    const isLight = componentParams.theme === 'light';
    const { locale = 'en' } = useRouter();
    const origin = typeof window !== 'undefined' ? window.location.origin : '';
    const markdownProps = { language: locale, origin };

    const renderContentBlock = (block: AccordionContentBlock) => {
      const contentType = block.heading
        ? 'heading'
        : block.description
        ? 'description'
        : block.unorderedList
        ? 'unorderedList'
        : block.orderedList
        ? 'orderedList'
        : block.links
        ? 'links'
        : '';

      switch (contentType) {
        case 'description':
          return (
            <Markdown key={`description_${block.description}`} {...markdownProps}>
              {block.description!}
            </Markdown>
          );
        case 'heading':
          return (
            <div key={`heading_${block.heading}`} className="mb-2 text-xl font-bold">
              <Markdown {...markdownProps}>{block.heading!}</Markdown>
            </div>
          );
        case 'unorderedList':
          return Array.isArray(block.unorderedList) ? (
            <ul key={`ul_${block.unorderedList[0]}`} className="ms-7 list-disc">
              {block.unorderedList.map((listItem) => (
                <li key={listItem}>
                  <Markdown {...markdownProps}>{listItem}</Markdown>
                </li>
              ))}
            </ul>
          ) : null;

        case 'orderedList':
          return Array.isArray(block.orderedList) ? (
            <ol className="ms-7 list-decimal" key={`ol_${block.orderedList[0]}`}>
              {block.orderedList.map((listItem) => (
                <li key={listItem}>
                  <Markdown {...markdownProps}>{listItem}</Markdown>
                </li>
              ))}
            </ol>
          ) : null;

        case 'links':
          return Array.isArray(block.links)
            ? block.links.map((link) => (
                <div key={link.linkUrl}>
                  <Link
                    url={link.linkUrl}
                    adaDescription={link.adaDescription}
                    isNewWindow={link.isNewWindow}
                  >
                    {link.linkLabel}
                  </Link>
                </div>
              ))
            : null;

        default:
          return null;
      }
    };

    if (filteredItems[0]?.isFaq && filteredItems[0]?.faqContent) {
      return (
        <BrandComponentThemeInline
          componentParams={componentParams}
          brandCode={brandCode}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration || 'none',
          }}
        >
          <Accordion
            headline={listData?.headline || undefined}
            description={listData?.description || undefined}
            brandComponentTheme={componentParams.theme}
            details={filteredItems[0].faqContent.map((faqDetail) => ({
              ...faqDetail,
              content: (
                <div
                  className={cx('border-border border-b p-6', {
                    'text-text-inverse': isDark,
                    'brand-ou:border-primary brand-ht:text-text-inverse': isLight,
                  })}
                >
                  {faqDetail.content.map(renderContentBlock)}
                </div>
              ),
            }))}
          />
        </BrandComponentThemeInline>
      );
    } else {
      const details = filteredItems.map((item) => ({
        ...item,
        content: (
          <div
            className={cx('border-border border-b p-6', {
              'text-text-inverse': isDark,
              'brand-ou:border-primary brand-ht:text-text-inverse': isLight,
            })}
          >
            {item?.content?.map(renderContentBlock)}
          </div>
        ),
      }));

      return (
        <BrandComponentThemeInline
          componentParams={componentParams}
          brandCode={brandCode}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration || 'none',
          }}
        >
          <Accordion
            details={details}
            headline={listData?.headline || undefined}
            description={listData?.description || undefined}
            brandComponentTheme={componentParams.theme}
          />
        </BrandComponentThemeInline>
      );
    }
  }
);
