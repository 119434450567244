import { HeadingLevel } from '@dx-ui/osc-heading-level';
import cx from 'classnames';

export type BrandTextHeaderType = {
  children?: React.ReactNode;
  ariaLabel?: string;
  className?: string;
  id?: string;
};

/**
 * Extra, Extra! Announcing the Text Block Header, consumed by other components to create a bold headline.
 */
export const BrandTextHeader = ({ children, ariaLabel, className = '', id }: BrandTextHeaderType) =>
  children ? (
    <HeadingLevel
      headingLevelFallback={2}
      className={cx(className, 'heading-2xl lg:mb-2 lg:heading-4xl')}
      data-testid="brandTextHeader"
      id={id}
    >
      {children}

      {ariaLabel && <span className="sr-only">{ariaLabel}</span>}
    </HeadingLevel>
  ) : null;

export default BrandTextHeader;
