import Cookies from 'universal-cookie';
import { addDays } from 'date-fns';
import { isBrowser } from '../utils/constants';
import type { NextPageContext } from 'next';
import { getCookieFromContext } from './cookies';

const cookieName = 'visitorId';

export function getVisitorId() {
  if (!isBrowser) {
    return null;
  }
  const visitorId: string = new Cookies().get(cookieName);
  return visitorId || setVisitorId();
}

function setVisitorId() {
  if (isBrowser) {
    const uuid = crypto.randomUUID();
    new Cookies().set(cookieName, uuid, {
      expires: addDays(new Date(), 90),
      path: '/',
    });
    return uuid;
  }
  return null;
}

export function getHltClientMessageId(visitorId?: string) {
  const vId = visitorId || getVisitorId();
  const requestId = Math.random().toString(36).substring(2);
  return vId ? `${vId}-${requestId}` : '';
}

export function getHltClientMessageIdFromContext(context: NextPageContext) {
  const visitorId = getVisitorIdFromContext(context);
  return getHltClientMessageId(visitorId);
}

export function getVisitorIdFromContext(context: NextPageContext) {
  return getCookieFromContext(cookieName, context) ?? '';
}
