import type { Calendar as CalendarType } from '@dx-ui/osc-calendar';
import { Calendar } from '@dx-ui/osc-calendar';
import { useLocale } from '@dx-ui/utilities-dates';
import { isBefore, addDays } from 'date-fns';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
/**
 * Sub component containing calendar with shop form business logic applied
 * @returns JSX.Element
 */

export type DatePickerCalendarProps = {
  day?: Date;
  dayFieldName: string;
  endDay?: Date;
  endDayFieldName: string;
  /**
   * option to only search for single day stays
   */
  isDayUse?: boolean;
  language: string;
} & Pick<CalendarType, 'allowSameDay' | 'allowPrevious' | 'dayLabel' | 'maxDays' | 'enabledDates'>;

export const DatePickerCalendar = ({
  allowPrevious,
  allowSameDay,
  day,
  dayFieldName,
  dayLabel,
  isDayUse,
  endDay,
  endDayFieldName,
  enabledDates,
  language,
  maxDays,
}: DatePickerCalendarProps) => {
  const { t } = useTranslation('osc-date-picker');
  const [message, setMessage] = useState('');
  const { setValue } = useFormContext();
  const locale = useLocale({ language });

  const onDayChange = (d?: Date) => {
    if (d) {
      setValue(dayFieldName, d, { shouldDirty: true, shouldValidate: true });
      const departureDate = isDayUse ? d : endDay && isBefore(endDay, d) ? addDays(d, 1) : endDay;
      setValue(endDayFieldName, departureDate, { shouldDirty: true, shouldValidate: true });
      setMessage(
        t(isDayUse ? 'selectedDayUseMessage' : 'selectedDateMessage', {
          fromDate: d.toLocaleDateString(locale, { dateStyle: 'long' }),
        })
      );
    }
  };
  const onEndDayChange = (d?: Date) => {
    setValue(endDayFieldName, d, { shouldDirty: true, shouldValidate: true });
    if (d) {
      setMessage(
        t('selectedEndDateMessage', {
          fromDate: day?.toLocaleDateString(locale, {
            dateStyle: 'long',
          }),
          toDate: d.toLocaleDateString(locale, { dateStyle: 'long' }),
        })
      );
    }
  };

  return (
    <Calendar
      formId="date-picker-calendar"
      dayId="arrivalDate"
      endDayId="departureDate"
      allowPrevious={allowPrevious}
      allowSameDay={allowSameDay}
      maxDays={maxDays}
      locale={locale}
      day={day}
      endDay={endDay}
      onDayChange={onDayChange}
      onEndDayChange={onEndDayChange}
      instructions={t('instructions')}
      message={message}
      enabledDates={enabledDates}
      dayLabel={dayLabel}
    />
  );
};
