import * as React from 'react';
import type { HeadingLevel as AccordionHeadingLevel } from '@dx-ui/osc-accordion';
import { Accordion as Details } from '@dx-ui/osc-accordion';
import { useTranslation } from 'next-i18next';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import cx from 'classnames';
import { HeadingLevel, useHeadingLevel } from '@dx-ui/osc-heading-level';

export type AccordionProps = {
  details: Array<{
    key: string;
    collapsedButtonLabel: string;
    expandedButtonLabel: string;
    content: React.ReactNode;
  }>;
  headline?: string;
  description?: string;
  brandComponentTheme?: CmsBrandComponentTheme;
};

export const Accordion = ({
  details,
  headline,
  description,
  brandComponentTheme,
}: AccordionProps) => {
  const [t] = useTranslation('cpm-mapping-shared');
  const [, level] = useHeadingLevel();
  let _accordionHeadingLevel: number | undefined = headline ? level + 1 : level;

  if (_accordionHeadingLevel > 6) {
    _accordionHeadingLevel = undefined;
  }

  const headingLevel = _accordionHeadingLevel
    ? (`h${_accordionHeadingLevel}` as AccordionHeadingLevel)
    : undefined;

  const [expandedAccordions, setExpandedAccordions] = React.useState<Array<string>>([]);

  const areAllExpanded = details.length === expandedAccordions.length;

  const expandAll = () => {
    setExpandedAccordions(
      details.map((detail) => {
        return detail.key;
      })
    );
  };
  const collapseAll = () => {
    setExpandedAccordions([]);
  };

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <div className="container">
      {headline ? (
        <HeadingLevel
          headingLevelFallback={2}
          className={cx('brand-hh:text-text heading-2xl lg:heading-2xl lg:mb-2', {
            '!text-text-inverse': isDark,
            'brand-ht:text-text-inverse': isLight,
          })}
        >
          {headline}
        </HeadingLevel>
      ) : null}

      {description ? (
        <BrandTextBody
          className={cx('mb-6', {
            '!text-text-inverse': isDark,
            'brand-ht:text-text-inverse': isLight,
          })}
        >
          {description}
        </BrandTextBody>
      ) : null}
      <button
        onClick={areAllExpanded ? collapseAll : expandAll}
        className={cx('btn btn-primary-link btn-xl mb-6', {
          'text-text-inverse': isDark,
          'brand-ht:text-text-inverse': isLight,
        })}
        type="button"
      >
        {areAllExpanded ? t('collapseAll') : t('expandAll')}
      </button>
      <Details
        className={cx('accordion-stacked border-border border-b', {
          'text-text-inverse': isDark,
          'brand-ou:border-primary brand-ht:text-text-inverse': isLight,
        })}
        items={details}
        type="multiple"
        variant="stacked"
        onValueChange={(keys) => {
          setExpandedAccordions(keys);
        }}
        value={expandedAccordions}
        headingLevel={headingLevel}
      />
    </div>
  );
};

export default Accordion;
