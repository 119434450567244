import { useRef } from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRect } from '@dx-ui/utilities-use-rect';
import { ResponsiveImage } from '@dx-ui/osc-responsive-image';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';
import type { CaptionProps } from '@dx-ui/osc-caption';
import type { Link } from '@dx-ui/osc-link';

export type TDynamicGridItem = {
  /** Dynamic Grid item id */
  id: string;
  /** Image for Dynamic Grid Item */
  imageUrl: string;
  /** Alt Text for Dynamic Grid Item Image */
  imageAltTxt: string;
  /** Image for Displaying in Modal */
  modalImageUrl?: string;
  /** Alt text for Modal Image */
  modalImageAltText?: string;
  /** Text overlaid on Dynamic Grid Item */
  itemTitle?: string;
  /** Headline for Dynamic Grid Item Modal/dialog */
  headline?: string;
  /** Content such as Markdown, or text in Modal/dialog */
  shortDescription?: JSX.Element | string;
  /** Link following the item headline & content in Modal/dialog */
  link?: React.ComponentProps<typeof Link>;
  /** Callback handler for grid item click event  */
  onItemClick?: (id: string) => void;
  /** Callback handler for link click event  */
  onClickLink?: (id?: string) => void;
  /** Add Tailwind classes to root element */
  className?: string;
  /** Caption link and text */
  captionData?: CaptionProps;
  /** List of Audience Ids */
  segmentIds: string[];
  /** CMS document editor button **/
  cmsDocumentControl?: React.ReactNode;
  /* Translation CSS classes to add to each item */
  cmsTranslationClasses?: string;
};

export type TDynamicGridItemAndAspectRatios = TDynamicGridItem & {
  /** The aspect ratio for the grid item images */
  imageAspectRatio: AspectRatio;
};

export const DocsTDynamicGridItem: React.FC<
  React.PropsWithChildren<TDynamicGridItemAndAspectRatios>
> = () => null;

const DynamicGridItem: React.FC<TDynamicGridItemAndAspectRatios> = (item) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const [t] = useTranslation('osc-dynamic-grids');

  const { id, captionData, itemTitle, imageUrl, onItemClick, className } = item;
  const ar = item?.imageAspectRatio;

  const onClickGridItem = () => {
    onItemClick && onItemClick(id);
  };
  // TODO: NHCBP-3494 - Tailwind utitilies for grid-* classes (see grid.css)
  // TODO: NHCBP-3494 - Tailwind focus:ring utility not working - classNames on "button":
  // focus:ring-2 focus:ring-primary focus:ring-opacity-50

  if (item) {
    return (
      <button
        type="button"
        data-testid={`dynamicgrid-item-${id}`}
        id={id}
        onClick={onClickGridItem}
        className={cx(
          'group overflow-y-hidden sm:w-auto',
          'focus:ring-primary focus:ring-2 focus:ring-opacity-50',
          'snap snap-start',
          'relative',
          item.cmsTranslationClasses,
          className
        )}
      >
        <div className="bg-bg-alt relative sm:overflow-hidden">
          <div className={cx('absolute z-10 flex h-full w-full items-end')}>
            <div className="from-bg-inverse size-full bg-gradient-to-t to-transparent">
              <div
                className={cx(
                  'flex h-full w-full items-end',
                  'transform duration-200 ease-in-out group-hover:-translate-y-1/3'
                )}
              >
                <div
                  className={cx(
                    'mx-auto w-full px-3 sm:py-4 lg:py-6 xl:text-3xl leading-tight dynamic-grid-item-header'
                  )}
                  data-testid="dynamicgrid-item-btnText"
                >
                  {itemTitle}
                </div>
                <span className="sr-only">{t('openModal')}</span>
              </div>
            </div>
          </div>
          <div ref={ref}>
            <ResponsiveImage
              id={`dynamic-grid-item-img-${id}`}
              aspectRatio={ar}
              imageUrl={imageUrl}
              altText=""
              width={rect?.width ?? 0}
              captionData={captionData}
            />
          </div>
        </div>
        {item.cmsDocumentControl}
      </button>
    );
  }

  return null;
};

export { DynamicGridItem };
export default DynamicGridItem;
