import type { FC } from 'react';
import { createRef, useCallback, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import { Icon } from '@dx-ui/osc-icon';
import { HeadingLevel } from '@dx-ui/osc-heading-level';

import { getPrefersReducedMotion } from '../utils/get-prefers-reduced-motion';
import type { TBrandShowcase, TBrandShowcaseItem } from './brand-showcase.types';
import { useTranslation } from 'next-i18next';
import { useDebounceCallback } from 'usehooks-ts';

export const NavItemWidth = 224; // px
export const END_MARGIN = 5; // px
export const SLIDE_DURATION = 0.5; // seconds

/**
 * List of the Hilton brands in a carousel like form. Each brand includes a logo, additional info about the currently selected brand, a link to the brand page,
 * and includes navigation controls to cycle through each brand.
 */
export const BrandShowcase: FC<TBrandShowcase> = ({ items, id, onItemClicked, logoUrl }) => {
  const { t } = useTranslation('osc-marketing-brand-showcase');

  const [selectedItem, setSelectedItem] = useState<TBrandShowcaseItem | undefined>(items[0]);
  const [scrolledToFarRight, setScrolledToFarRight] = useState(false);

  const scrollAnimationRequest = useRef<number | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const itemRefs = useMemo(() => items.map(() => createRef<HTMLButtonElement>()), [items]);

  const onShowcaseItemClicked = (item: TBrandShowcaseItem | undefined) => {
    const index = items?.findIndex((i) => i === item);
    if (item !== selectedItem && scrollRef.current) {
      const totalWidth = scrollRef.current.scrollWidth;
      const { offsetWidth } = scrollRef.current;
      //Getting width of the first brand item which is equal to all other brand items
      const brandRect = scrollRef?.current?.children[0]?.getBoundingClientRect();
      const width = brandRect?.width || 0;

      let desiredScrollLeft = index * width;
      if (desiredScrollLeft > totalWidth - offsetWidth) {
        desiredScrollLeft = totalWidth - offsetWidth;
      }
      const onComplete = () => {
        const previousItem = selectedItem;
        setSelectedItem(item);
        itemRefs[index]?.current?.focus();

        previousItem !== item && onItemClicked && onItemClicked(index);
      };
      if (scrollRef.current.scrollLeft !== desiredScrollLeft) {
        scrollToOffset(desiredScrollLeft, onComplete);
      } else {
        onComplete();
      }
    }
  };

  const scrollToOffset = useCallback(
    (desiredScrollLeft: number, onComplete?: () => void) => {
      if (scrollRef.current) {
        if (scrollAnimationRequest.current) {
          cancelAnimationFrame(scrollAnimationRequest.current);
          scrollAnimationRequest.current = null;
        }

        const { scrollLeft } = scrollRef.current;

        if (getPrefersReducedMotion()) {
          scrollRef.current.scrollLeft = desiredScrollLeft;
          onComplete && onComplete();
        } else {
          const scrollDistance = desiredScrollLeft - scrollLeft;
          const duration = Math.max(350, Math.abs(scrollDistance) * 0.6);
          let startTime: number;

          const animateScroll = (timeStamp: number) => {
            if (!startTime) {
              startTime = timeStamp;
            }

            const progress = (timeStamp - startTime) / duration;
            const easing =
              progress < 0.5
                ? SLIDE_DURATION * progress * progress
                : (4 - 2 * progress) * progress - 1;

            if (scrollRef.current) {
              scrollRef.current.scrollLeft = scrollLeft + scrollDistance * easing;

              if (progress < 1) {
                scrollAnimationRequest.current = requestAnimationFrame(animateScroll);
              } else {
                scrollAnimationRequest.current = null;
                onComplete && onComplete();
              }
            }
          };

          scrollAnimationRequest.current = requestAnimationFrame(animateScroll);
        }
      }
    },
    [scrollAnimationRequest, scrollRef]
  );

  const debouncedOnScroll = useDebounceCallback(() => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth: totalWidth, offsetWidth } = scrollRef.current;
      const marginForDetectingBrandPosition = 7;

      const distanceFromEndOfScrollBar = totalWidth - offsetWidth - scrollLeft;
      const endOfScrollBar =
        distanceFromEndOfScrollBar < marginForDetectingBrandPosition &&
        distanceFromEndOfScrollBar > -marginForDetectingBrandPosition;

      //Getting width of the first brand item which is equal to all other brand items
      const brandRect = scrollRef?.current?.children[0]?.getBoundingClientRect();
      const width = brandRect?.width || 0;

      const index = Math.min(items.length - 1, Math.ceil(scrollLeft / Math.ceil(width)));

      const currentIndex = items.findIndex((i) => i === selectedItem);

      // Don't adjust if we aren't at a scroll stop point unless we are at the end of the bar and currentIndex is before index
      if (!endOfScrollBar || (endOfScrollBar && currentIndex < index)) {
        const previousItem = selectedItem;
        setSelectedItem(items[index]);
        itemRefs[index]?.current?.focus();

        previousItem !== items[index] && onItemClicked && onItemClicked(index);
      }
      setScrolledToFarRight(endOfScrollBar);
    }
  }, 300);

  const onScrollWrapper = () => {
    if (scrolledToFarRight && scrollRef.current) {
      const { scrollLeft, offsetWidth, scrollWidth: totalWidth } = scrollRef.current;

      const distanceFromEndOfScrollBar = totalWidth - offsetWidth - scrollLeft;
      const endOfScrollBar = Math.abs(distanceFromEndOfScrollBar) < END_MARGIN;
      if (!endOfScrollBar) {
        setScrolledToFarRight(false);
      }
    }
    debouncedOnScroll();
  };

  const handleTabListWrapperKeyDown = (evt: React.KeyboardEvent) => {
    const index = items.findIndex((i) => i.code === selectedItem?.code);
    switch (evt.key) {
      case 'ArrowLeft':
        evt.preventDefault();
        evt.stopPropagation();

        if (index !== 0) {
          onShowcaseItemClicked(items[index - 1]);
        }

        break;
      case 'ArrowRight':
        evt.preventDefault();
        evt.stopPropagation();

        if (index !== items.length - 1) {
          onShowcaseItemClicked(items[index + 1]);
        }

        break;
      default:
    }
  };

  const slide = (direction: number) => {
    const index = items.findIndex((i) => i.code === selectedItem?.code);
    let targetIndex;
    if (direction < 0) {
      targetIndex = index === 0 ? 0 : index - 1;
      // if we are at the end, make sure to update the endrow state
      if (targetIndex < items.length - 1) {
        setScrolledToFarRight(false);
      }
    } else {
      targetIndex = index === items.length - 1 ? items.length - 1 : index + 1;
    }
    onShowcaseItemClicked(items[targetIndex]);
  };

  const index = items.findIndex((i) => i.code === selectedItem?.code);

  const NavItems = items.map((item, idx) => (
    <div
      className="relative w-full flex-none snap-start p-1 md:w-2/6 lg:w-1/5 xl:w-1/6"
      key={item.code}
    >
      <button
        className="relative h-36 w-full"
        tabIndex={selectedItem?.name === item.name ? undefined : -1}
        onClick={() => onShowcaseItemClicked(item)}
        role="tab"
        aria-expanded={selectedItem?.name === item.name}
        aria-controls={`brands-showcase-panel-${id}`}
        ref={itemRefs[idx]}
        aria-label={item.name}
        onKeyDown={handleTabListWrapperKeyDown}
        data-testid="brands-showcase-brand-button"
        type="button"
      >
        <div className="bg-bg-alt relative size-full overflow-hidden">
          <Image
            id={id}
            style={{
              objectFit: 'contain',
              fill: item.imageDefaultColor,
            }}
            // fill-[imageDefaultColor] does not fill if the SVG images already
            // have fill colors built-in like all of the 20 brand logos on the
            // Hilton.com homepage (portfolio site)
            fill
            src={`${logoUrl}/${item.code}.svg`}
            alt={item.name}
          />
        </div>
      </button>
      {selectedItem?.name === item.name ? (
        <div className="absolute bottom-0 h-2 w-full">
          <div className="bg-bg top-0 mx-auto size-6 origin-center rotate-45" />
        </div>
      ) : null}
    </div>
  ));
  return (
    <div className="bg-bg-alt relative w-full" data-testid="brandShowcaseWrapper" id={id}>
      <div className="container flex flex-col items-start py-14 pl-4 lg:py-16">
        <div className="px-0 sm:pl-6">
          <HeadingLevel
            headingLevelFallback={2}
            className="text-primary heading-2xl sm:heading-3xl lg:heading-4xl pb-4 pt-1 font-bold leading-tight"
          >
            {t('waysToStay')}
          </HeadingLevel>
        </div>
        <div className="relative flex w-full">
          {index > 0 ? (
            <button
              className="btn btn-primary-text btn-lg absolute inset-y-0 left-0 z-10 m-1"
              onClick={() => slide(-1)}
              data-testid="previous"
              type="button"
            >
              <span className="sr-only">{t('returnsToThePreviousBrand')}</span>
              <Icon name="arrowhead-left" size="2xl" />
            </button>
          ) : null}
          <div className="relative w-full overflow-hidden px-12">
            <div className="absolute left-0 top-0 inline-block h-full w-5 bg-gradient-to-l" />
            <div
              className={`absolute right-4 top-0 inline-block h-full ${
                scrolledToFarRight ? 'w-0' : 'w-8 md:w-32'
              } bg-gradient-to-r`}
            />
            <div
              className="snap mx-auto flex w-56 snap-x snap-mandatory overflow-x-auto overflow-y-hidden px-0 py-1 md:mx-0 md:w-full lg:overflow-x-scroll"
              ref={scrollRef}
              onScroll={onScrollWrapper}
              role="tablist"
              data-testid="onScrollWrapper"
            >
              {NavItems}
            </div>
          </div>
          {index < items.length - 1 ? (
            <button
              className="btn btn-primary-text btn-lg absolute inset-y-0 right-0 m-1"
              onClick={() => slide(1)}
              data-testid="next"
              type="button"
            >
              <span className="sr-only">{t('advancesToTheNextBrand')}</span>
              <Icon name="arrowhead-right" size="2xl" />
            </button>
          ) : null}
        </div>
        <div className="flex w-full flex-col sm:pl-6" id={`brands-showcase-panel-${id}`}>
          {items.map((item /* we render all tabs for SEO purposes */) => (
            <div
              className={`${selectedItem?.name === item.name ? 'flex' : 'hidden'} w-full flex-col`}
              key={item.name}
              data-testid={
                selectedItem?.name === item.name
                  ? 'brand-showcase-panel-active'
                  : 'brand-showcase-panel'
              }
            >
              <div className="bg-bg mb-8 w-full px-8 pt-8">
                <h3 className="text-2xl font-bold leading-tight sm:text-3xl lg:text-4xl">
                  {item.name}
                </h3>
                <p className="py-5 sm:text-lg md:pb-8 lg:text-xl">{item.shortDescription}</p>
              </div>
              <div className="flex w-full flex-col items-center">
                <a className="btn btn-primary btn-xl items-center" href={item.url || `/en/brands/`}>
                  {item.label || t('visitBrand', { brand: item.name })}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
