/**
 * Defaults that all apps should import.
 */
export const defaultPublicVars = [
  'APP_ENV',
  'APP_NAME',
  'APP_VER',
  'DX_AUTH_API_CLIENT_URI',
  'DX_AUTH_API_CUSTOMER_APP_ID',
  'DX_AUTH_UI',
  'DX_GRAPHQL_CLIENT_URI',
  'EXTERNAL_HGV_PROGRAM_DETAILS_URL',
  'HONORS_JOIN_URL',
  'OHW_BASE_URL',
  'RUMCS_CAPTURE_CUSTOM_ERRORS',
  'TRACKING_SEED_FILE',
  'TMTP_MANAGE_FAMILY_AND_FRIENDS_LINK',
  'EXTERNAL_TMTP_PROGRAM_DETAILS_URL',
] as const;
