import * as React from 'react';
import { ImageHeadlinerContent } from './image-headliner-content';
import { ImageHeadlinerControls } from './image-headliner-controls';
import cx from 'classnames';
import type { Pagination } from '@dx-ui/osc-pagination';
import { Cycle } from '@dx-ui/osc-cycle';
import { ImageHeadlinerItem } from './image-headliner-item';

export type TImageHeadliner = Pick<Pagination, 'label' | 'loop' | 'hideLabel' | 'current'> &
  React.HTMLAttributes<HTMLDivElement>;

type ImageHeadlinerType = {
  label?: string;
  className?: string;
} & TImageHeadliner;

/**
 * Used to grab a user's attention with a bold background color and text. Alerts users to promotions, new hotels for humans or supernatural beings, important legal updates.
 * Contains an image/logo, headline text and a link to more information.
 */
export const ImageHeadliner: React.FC<ImageHeadlinerType> = ({
  current,
  className = '',
  hideLabel,
  loop,
  label,
  children,
  id = 'image-headliner-cycle-id',
  ...rest
}) => {
  const validSlides = React.Children.map(
    children,
    (child) => React.isValidElement(child) && child.type === ImageHeadlinerItem && child
  )?.filter(Boolean) as unknown as (typeof ImageHeadlinerItem)[];

  const slides = React.Children.map(
    validSlides,
    (child, index) =>
      React.isValidElement(child) &&
      React.cloneElement(
        child as React.ReactElement<React.ComponentProps<typeof ImageHeadlinerItem>>,
        {
          index,
        }
      )
  );
  const totalSlides = React.Children.count(slides);

  return (
    <Cycle id={id} current={current} total={totalSlides}>
      <div
        className={cx(
          className,
          'image-headliner-bg relative flex w-full flex-col sm:flex-row items-center py-2'
        )}
        data-testid="imageHeadliner"
      >
        <ImageHeadlinerContent {...rest}>{slides}</ImageHeadlinerContent>
        <ImageHeadlinerControls label={label} hideLabel={hideLabel} loop={loop} />
      </div>
    </Cycle>
  );
};

export default ImageHeadliner;
