import { useRef } from 'react';
import cx from 'classnames';
import type { Link } from '@dx-ui/osc-link';
import type { DialogBaseProps } from '@dx-ui/osc-dialog-v2';
import { Dialog } from '@dx-ui/osc-dialog-v2';
import { useRect } from '@dx-ui/utilities-use-rect';
import { BrandThemeWrapper } from '../brand-theme';
import { ResponsiveImage, getCurrentAspectRatioAndUrl } from '@dx-ui/osc-responsive-image';
import type { AspectRatio, TResponsiveImage } from '@dx-ui/osc-responsive-image';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import { BrandLink } from '@dx-ui/osc-brand-buttons';

export type DialogWithContentBase = {
  ariaLabel?: string;
  id?: string;
  /**
   *Appears at the top of the dialog
   */
  title?: string;
  /**
   * Appears under the title, headline of content
   */
  headline?: string;
  /**
   * Can include images, text, markdown
   */
  content?: string | JSX.Element;
  /**
   * CTA link/button, opens in another tab
   */
  link?: Link;
  onClose?: () => void;
  themeWrapperProps?: Pick<React.ComponentProps<typeof BrandThemeWrapper>, 'brandCodeForTheme'>;
};

type ImageUrlsType = {
  tabletImageUrl: string;
  mobileImageUrl: string;
  desktopImageUrl: string;
};

export type DialogWithContentType = DialogWithContentBase &
  Omit<DialogBaseProps, 'content'> & {
    isOpen: boolean;
    imageUrl?: string;
    captionData?: TResponsiveImage['captionData'];
    imageUrls?: ImageUrlsType;
    imageAltText?: string;
    containerAspectRatio?: AspectRatio;
    imageAspectRatio?: AspectRatio;
    cmsTranslationClasses?: string;
  };

export type AspectRatioGroup = {
  aspectRatioMobile: AspectRatio;
  aspectRatioTablet: AspectRatio;
  aspectRatioDesktop: AspectRatio;
};

/**
 * DialogWithContent renders many different styles of modal using the content passed into it.
 *
 * It suports rendering images, links, component children and markdown.
 */
export const DialogWithContent = (props: DialogWithContentType) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });

  const {
    imageAspectRatio = '3:2',
    imageUrl,
    imageUrls,
    imageAltText,
    captionData,
    isOpen,
    id = 'dialog',
    ariaLabel,
    title,
    headline,
    content,
    link,
    onClose,
    cmsTranslationClasses,
    ...dialogProps
  } = props;

  let { themeWrapperProps } = props;

  const isHeadlineShown = headline && headline !== props.title;

  let body: JSX.Element | null = null;
  if (content) {
    if (typeof content === 'string') {
      body = <BrandTextBody {...props}>{content}</BrandTextBody>;
    } else {
      body = <div className="prose lg:prose-xl my-2">{content}</div>;
    }
  }

  const { imageUrl: multipleImageUrl, aspectRatio: multipleImageAspectRatio } = imageUrls
    ? getCurrentAspectRatioAndUrl({
        width: rect?.width || 700,
        imageUrlMobile: imageUrls.mobileImageUrl,
        imageUrlTablet: imageUrls.tabletImageUrl,
        imageUrlDesktop: imageUrls.desktopImageUrl,
        aspectRatioMobile: imageAspectRatio,
        aspectRatioTablet: imageAspectRatio,
        aspectRatioDesktop: imageAspectRatio,
      })
    : { imageUrl: null, aspectRatio: null };

  if (!themeWrapperProps) {
    /* If no theme props passed, set default theme */
    themeWrapperProps = { brandCodeForTheme: 'HH' };
  }
  const dialogPropsWithLabel = title ? { title } : { ariaLabel: ariaLabel || '' };

  return (
    <Dialog
      data-testid="activeDialogWithContentBox"
      isOpen={isOpen}
      onDismiss={onClose}
      size="6xl"
      {...dialogProps}
      {...dialogPropsWithLabel}
    >
      <BrandThemeWrapper {...themeWrapperProps}>
        <div className={cx('flex h-full flex-col sm:h-auto', cmsTranslationClasses)} ref={ref}>
          {/* This is needed in case we only need 1 img src that can be used for tablet, desktop and mobile screens */}
          {imageUrl ? (
            <div className="flex items-center justify-items-center overflow-y-hidden">
              <ResponsiveImage
                id={id}
                aspectRatio={imageAspectRatio}
                imageUrl={imageUrl}
                altText={imageAltText || ''}
                width={rect?.width || 700}
                captionData={captionData}
              />
            </div>
          ) : null}
          {/* This is needed in case we have separate Urls for tablet, desktop and mobile screens */}
          {/* Also, "&& !imageUrl" added just to prevent rendering 2 images if both props somehow get passed */}
          {imageUrls && !imageUrl ? (
            <div className="flex items-center justify-items-center overflow-y-hidden">
              <ResponsiveImage
                id={id}
                aspectRatio={multipleImageAspectRatio || '3:2'}
                imageUrl={multipleImageUrl || ''}
                altText={imageAltText || ''}
                width={rect?.width || 700}
                captionData={captionData}
              />
            </div>
          ) : null}

          <div className="box-border px-4 pb-2 pt-8 sm:px-8 md:px-16">
            {isHeadlineShown ? (
              <BrandTextHeader {...props} className="brand-wa:heading-xl brand-wa:md:heading-2xl">
                {headline}
              </BrandTextHeader>
            ) : null}

            {body}

            {link?.label && link?.url ? (
              <div className="inline-block w-full pb-4 pt-8 text-center">
                <BrandLink
                  label={link.label}
                  isNewWindow={link.isNewWindow}
                  showNewWindowIcon={link.isNewWindow}
                  url={link.url}
                  onClick={link?.onClick}
                  cidParams={link?.cidParams}
                />
              </div>
            ) : null}
          </div>
        </div>
      </BrandThemeWrapper>
    </Dialog>
  );
};

export default DialogWithContent;
