import type { MappedComponentName } from '../schema';
import type { CpmMappedComponentData, CpmComponentDefinition, CpmMappingFunction } from './types';

export function createCpmComponentDefinition<
  ComponentName extends MappedComponentName,
  MappingOutput
>(
  cpmComponentName: ComponentName,
  mapData: CpmMappingFunction<ComponentName, MappingOutput>,
  component: React.ComponentType<
    CpmMappedComponentData<
      ComponentName,
      ReturnType<CpmMappingFunction<ComponentName, MappingOutput>>
    >
  >
): CpmComponentDefinition<ComponentName, MappingOutput> {
  return {
    cpmComponentName,
    mapData,
    component,
  };
}
