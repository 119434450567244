import { useState, useEffect } from 'react';
import { initialize } from '@bloomreach/spa-sdk';

import { makeClientBrConfiguration } from '../fetching/make-client-br-configuration';
import type { CpmClientBundle } from './types';
import { extractMappedPage } from './cpmMappedPage';
import { logError } from '@dx-ui/framework-logger';

type ClientCpmPageError = { errorTitle: string; statusCode: 404 | 500 };
type Output =
  | (CpmClientBundle & { blueprint: string; pageType: string })
  | ClientCpmPageError
  | null;

export function useClientCpmPage(contentPath: string, localeCode: string): Output {
  const [output, setOutput] = useState<Output>(null);

  useEffect(() => {
    (async () => {
      try {
        setOutput(null);

        if (contentPath === undefined) {
          setOutput({
            statusCode: 404,
            errorTitle: 'Page Not Found',
          });
          return;
        }

        const bloomReachConfiguration = makeClientBrConfiguration(contentPath);
        const cpmPage = await initialize(bloomReachConfiguration);
        const cpmPageModel = cpmPage.toJSON();
        const mappedPage = extractMappedPage(contentPath, localeCode, cpmPage);
        const blueprint = (cpmPageModel?.channel?.info?.props?.blueprintName as string) ?? '';
        const rootDocument = cpmPage.getContent(cpmPageModel?.root.$ref);
        // @ts-expect-error - need to update types to reflect actual data
        const pageType = rootDocument?.name ?? '';

        setOutput({
          blueprint,
          pageType,
          mappedPage,
          cpmPage,
          bloomReachConfiguration,
          contentPath,
          __cpmPageModel: cpmPageModel,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        if (
          error instanceof Error &&
          error.name === 'TypeError' &&
          /Cannot read properties of undefined/.test(error.message)
        ) {
          // eslint-disable-next-line no-console
          console.error(
            'Failed to initialise Bloomreach. This can be caused by invalid JSON in the page response.'
          );
        }

        setOutput({
          statusCode: 500,
          errorTitle: 'Unavailable',
        });
      }
    })().catch((error) => logError('USE_CLIENT_CPM_PAGE', error, 'failed in useclientcpmpage()'));
  }, [contentPath, localeCode]);

  return output;
}
